<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="100vw"
      :fullscreen="true"
      :ref="modal_name">
    <v-card :loading="loading" :disabled="loading" height="100%" width="99%">
      <BaseModal>
        <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
          <v-btn icon @click="reset()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
          <v-btn :disabled="loading" text small @click="save()">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
        <div slot="content">
          <v-divider/>
          <v-card-text style="height: 98vh">
            <v-row style="height: 100%;">
              <v-col style="overflow-y: scroll; height: -webkit-fill-available;">
                <template v-for="field in usual_fields">
                  <base-field
                      :isDisabled="!canEditCustomRules(field)"
                      :key="field.name"
                      :value="record[field.name]"
                      :field="field"
                      @change="usualFieldDataChanged"/>
                </template>
                <base-row
                    class="mt-3"
                    v-for="(file, index) in record.files"
                    :key="index"
                    :label="$store.getters.translate('file')"
                    :value="file.name"
                    :hide_copy="true"
                    :deletable="false"/>
                <v-row>
                  <v-col v-cloak @drop.prevent="addDropFile" @dragover.prevent>
                    <v-file-input
                        class="mt-3"
                        :label="$store.getters.translate('files')"
                        placeholder="Drop file to upload"
                        show-size
                        v-model="record.new_files"
                        multiple
                        @change="saveFiles"/>
                  </v-col>
                </v-row>
                <v-list dense>
                  <v-toolbar flat dense>
                    {{ $store.getters.translate("punchlist_linked_assets") }}
                    <v-spacer/>
                    <v-btn @click.stop="linkPunchlistItem()" block text color="primary" prepend-icon small>
                      {{ $store.getters.translate('link_punchlist_item_to_asset') }}
                      <v-icon small class="ml-2">mdi-link</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-divider/>
                  <template v-for="(asset, index) in record.assets">
                    <v-list-item :key="index" color="primary">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ asset.name }}
                          <small v-if="asset.parent_id">({{ asset.platformName }})</small>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ moment(asset.created_at).format("DD-MM-YYYY HH:mm") }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
                <base-tags v-if="tags_required" :isDisabled="true" v-model="record.tags" :modal_name="page.name"/>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
        <base-link-asset modal_name="link_asset" ref="link_asset" class="mb-3" @refresh="addAssetIds" :current_assets="record.asset_ids" :module_name="page.name" :hide_platforms="true"/>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";
import BaseLinkAsset from "../../components/commonComponents/BaseLinkAsset";
import moment from "moment";

export default {
  props: ["modal_name", "platform_id"],
  components: {
    BaseLinkAsset,
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate('punchlistitem'),
        name: "punchlistitems",
        model: "punchlistitem"
      },
      loading: false,
      record: {
        assets: [],
        asset_ids: [],
        files: [],
        tags: [],
      },
      usual_fields: [],
      punchlist_owner_id: null,
      tags_required: false,
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    load(punchlist_id = null, punchlist_tags = [], punchlist_owner_id = null) {
      this.record = {
        punchlist_id: punchlist_id,
        tags: punchlist_tags,
        date_punched: moment(moment.now()).format('DD-MM-YYYY'),
        punched_by: this.$store.state.user.person.employee.id,
        assets: [],
        asset_ids: [],
        files: [],
      };
      this.punchlist_owner_id = punchlist_owner_id;
      this.openModal(this.modal_name);
      this.getFields();
    },
    addAssetIds(ids) {
      this.record.asset_ids = ids;
      this.record.assets = [];
      this.record.asset_ids.forEach((item) => {
        this.$http
            .get(this.$store.getters.appUrl + "v2/assets/" + item)
            .then((response) => {
              this.record.assets.push(response.data);
            })
            .catch((error) => {
              this.$toasted.error(error);
              this.loading = false;
            });
      });
    },
    save() {
      if(!this.loading) {
        if (helpFunctions.modelFieldsFilled(this.$toasted, this.record, this.usual_fields)) {
          this.loading = true;
          if (this.record.technical_authority_required == true) {
            this.record.technical_authority_required = 1;
          }
          if (this.record.technical_authority_approved == true) {
            this.record.technical_authority_approved = 1;
          }
          if (this.record.status == true) {
            this.record.status = 1;
          }
          this.record.active = 0;
          const formData = new FormData();
          if (this.record.files) {
            this.record.files.forEach((file) => {
              formData.append("attachment[]", file);
            });
          }
          let punched_by_field_found = false;
          let date_punched_field_found = false;
          this.usual_fields.forEach((field) => {
            if(field.name === 'punched_by') {
              punched_by_field_found = true;
            }
            if(field.name === 'date_punched') {
              date_punched_field_found = true;
            }
            if(field.name !== 'tags') {
              if (field.field_type !== 'boolean' && this.record[field.name] == undefined) {
                this.record[field.name] = '';
              }
              formData.append(field.name, this.record[field.name]);
            }
          });
          if(!punched_by_field_found) {
            formData.append('punched_by', this.record.punched_by);
          }
          if(!date_punched_field_found) {
            formData.append('date_punched', this.record.date_punched);
          }
          if (this.record.asset_ids.length > 0) {
            formData.append("asset_ids", this.record.asset_ids);
          }
          formData.append("tags", JSON.stringify(this.record.tags));
          this.$http
              .post(this.$store.getters.appUrl + "v2/" + this.page.name, formData, {headers: {"Content-Type": "multipart/form-data"}})
              .then((response) => {
                this.loading = false;
                if (response.status == 200) {
                  this.$toasted.success(this.$store.getters.translate("successfully_saved"));
                  this.$emit("refresh");
                  this.reset();
                } else {
                  var errorMessage = response.data.message;
                  var errors = Object.keys(response.data.message);
                  this.$toasted.error([errorMessage[errors[0]]]);
                }
              }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
        }
      }
    },
    linkPunchlistItem() {
      this.$refs.link_asset.tags = this.record.tags;
      if(this.platform_id) {
        this.$refs.link_asset.selected_platforms = [this.platform_id];
      }
      else {
        this.$refs.link_asset.load();
      }
    },
    saveFiles() {
      if (this.record.new_files) {
        for (var i = 0; i < this.record.new_files.length; i++) {
          this.record.files.push(this.record.new_files[i]);
        }
        this.record.new_files = [];
      }
    },
    addDropFile(e) {
      for (var i = 0; i < e.dataTransfer.files.length; i++) {
        this.record.files.push(e.dataTransfer.files[i]);
      }
    },
    canEditCustomRules(field) {
      //DEFAULT FIELDS THAT NEVER CAN BE EDITED
      if (field.name === 'date_punched' || field.name === 'punched_by' || field.name === 'punchlist_id' || field.name === 'technical_authority_approved') {
        return false;
      }
      //FIELDS THAT CAN BE EDITED ONLY BY PUNCHLIST OWNER
      if ((field.name === 'active' || field.name === 'status' || field.name === 'follow_up_by' || field.name === 'follow_up_wo_number' ||
          field.name === 'technical_authority_by' || field.name === 'technical_authority_required' || field.name === 'rejected' || field.name === 'reject_reason') &&
          this.$store.state.user.person.employee.id !== this.punchlist_owner_id) {
        return false;
      }
      //DEFAULT
      return true;
    },
    usualFieldDataChanged(data) {
      this.record[data[0]] = data[1];
    },
    async getFields() {
      this.loading = true;
      const response = await helpFunctions.getFields(this.page.model, "show_on_creation");
      this.tags_required = response.tags_required;
      this.usual_fields = response.usual_fields;
      this.loading = false;
    },
    reset() {
      this.record = {
        assets: [],
        asset_ids: [],
        files: [],
        tags: [],
      };
      this.closeModal(this.modal_name);
    },
  },
};
</script>