<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="100vw"
      :fullscreen="true"
      :ref="modal_name">
    <v-card :loading="loading" :disabled="loading" height="100%" width="99%">
      <BaseModal>
        <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
          <v-btn icon @click="closeModal(modal_name)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
          <v-btn v-if="canDelete(record.id || 0)" text small @click="archive()">
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
          <v-btn v-if="canEdit(record.id || 0)" text small @click="save()">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
        <div slot="content">
          <v-divider/>
          <v-card-text style="height: 98vh">
            <v-row style="height: 100%;">
              <v-col style="overflow-y: scroll; height: -webkit-fill-available;">
                <BaseStepper :active="record.active" :assigned="record.follow_up_by" :closed="record.status"/>
                <template v-for="field in usual_fields">
                  <base-field
                      :isDisabled="!canEdit(record.id || 0) || !canEditCustomRules(field)"
                      :key="field.name"
                      :value="record[field.name]"
                      :field="field"
                      @change="usualFieldDataChanged"/>
                  <!--CUSTOM FIX TO SET LOG FIELD BETWEEN USUAL FIELDS-->
                  <base-log-field
                      v-if="field.name === 'short_description'"
                      :key="field.name + '_log'"
                      class="mt-5"
                      @change="logsAdded"
                      :model_type="page.model"
                      :model_id="record.id"/>
                </template>
                <template v-if="record.files && record.files.length > 0">
                <base-row
                    class="mt-3"
                    v-for="(file, index) in record.files"
                    :key="index"
                    :label="$store.getters.translate('file')"
                    :value="file.name + ' created by ' + file.creator + ' on ' + moment(file.created_at).format('DD-MM-YYYY HH:mm')"
                    @click="download(file)"
                    :link="true"
                    :hide_copy="true"
                    :deletable="deletable"
                    @delete="deleteFile(file)"/>
                </template>
                <v-row>
                  <v-col v-cloak @drop.prevent="addDropFile" @dragover.prevent>
                    <v-file-input
                        :disabled="!canEdit(record.id || 0)"
                        class="mt-3"
                        :label="$store.getters.translate('files')"
                        show-size
                        v-model="record.new_files"
                        multiple
                        @change="saveFiles"/>
                  </v-col>
                </v-row>
                <v-toolbar flat dense>
                  {{ $store.getters.translate("punchlist_linked_assets") }}
                  <v-spacer/>
                  <v-btn :disabled="!canEdit(record.id || 0) ||
                          (employee_id !== record.punched_by && employee_id !== punchlist_owner_id) ||
                          ((record.active || record.status) && employee_id !== punchlist_owner_id)"
                         @click.stop="linkPunchlistItem()" block text color="primary" prepend-icon small>
                    {{ $store.getters.translate('link_punchlist_item_to_asset') }}
                    <v-icon small class="ml-2">mdi-link</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-divider/>
                <v-list dense>
                  <template v-for="(asset, index) in record.assets">
                    <v-list-item :key="index" color="primary">
                      <v-list-item-content>
                        <v-list-item-title>
                          <a @click="$emit('edit', asset.id)">
                            {{ asset.name }}
                            <small v-if="asset.parent_id">({{ asset.platformName }})</small>
                          </a>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ moment(asset.created_at).format("DD-MM-YYYY HH:mm") }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn :disabled="!canEdit(record.id || 0) ||
                                (employee_id !== record.punched_by && employee_id !== punchlist_owner_id) ||
                                ((record.active || record.status) && employee_id !== punchlist_owner_id)"
                               @click.stop="unlinkAsset(asset.id)" small text>
                          <v-icon color="primary" small>mdi-link-off</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list>
                <base-tags class="mt-5" v-if="tags_required" :isDisabled="true" v-model="record.tags" :modal_name="page.name"/>
                <v-btn v-if="employee_id !== punchlist_owner_id && record.active && !record.status" class="mt-5" :disabled="!canEdit(record.id || 0) || review_request_sent" @click="sendReviewRequest()" color="primary" block>
                  {{ $store.getters.translate("send_review_request") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
        <base-link-asset modal_name="link_asset" ref="link_asset" class="mb-3" @refresh="addAssetIds" :current_assets="record.asset_ids" :module_name="page.name" :hide_platforms="true"/>
        <base-review-request modal_name="send_review_request" ref="send_review_request" class="mb-3" :punchlistitem="record" :punchlist_owner_id="punchlist_owner_id"/>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";
import BaseLinkAsset from "../../components/commonComponents/BaseLinkAsset";
import BaseLogField from "../../components/commonComponents/BaseLogField";
import BaseReviewRequest from "../../components/commonComponents/BaseReviewRequest";
import BaseStepper from "../../components/commonComponents/BaseStepper";

export default {
  components: {
    BaseStepper,
    BaseReviewRequest,
    BaseLinkAsset,
    BaseModal,
    BaseDraggableModal,
    BaseLogField,
  },
  props: ["modal_name", "platform_id"],
  data() {
    return {
      page: {
        title: this.$store.getters.translate("edit_punchlistitem"),
        name: "punchlistitems",
        model: "punchlistitem"
      },
      loading: false,
      record: {
        assets: [],
        asset_ids: [],
        files: [],
        tags: [],
      },
      usual_fields: [],
      employee_id: null,
      punchlist_owner_id: null,
      review_request_sent: false,
      tags_required: false,
      deletable: false,
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    async load(id, punchlist_owner_id) {
      this.record = {
        assets: [],
        asset_ids: [],
        files: [],
        tags: [],
      };
      if(this.$store.getters.getUser.superuser) {
        this.deletable = true;
      }
      if(punchlist_owner_id) {
        this.punchlist_owner_id = punchlist_owner_id;
      }
      this.employee_id = this.$store.state.user.person.employee.id;
      this.openModal(this.modal_name);
      this.loading = true;
      this.record = await helpFunctions.loadModel(this.$toasted, this.page.name, id);
      this.record.asset_ids = this.$lodash.map(this.record.assets, 'id');
      await this.getFields();
    },
    addAssetIds(ids) {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/punchlistitems/linkassets", { punchlistitem_id: this.record.id, asset_ids: ids })
          .then(() => {
            this.loading = false;
            this.$toasted.success(this.$store.getters.translate("success"));
            this.load(this.record.id, this.punchlist_owner_id);
          }).catch((error) => {
        if (this.$store.getters.isLoggedIn) {
          this.$toasted.error(error);
        }
        this.loading = false;
      });
    },
    async save() {
      if(!this.loading) {
        if(this.record.rejected && (!this.record.reject_reason || this.record.reject_reason === '')) {
          this.$toasted.error(this.$store.getters.translate("reject_reason_required"));
        }
        else if(this.record.rejected && this.record.active) {
          this.$toasted.error(this.$store.getters.translate("reject_active_error"));
        }
        else {
          if (helpFunctions.modelFieldsFilled(this.$toasted, this.record, this.usual_fields)) {
            this.loading = true;
            if (await helpFunctions.saveModel(this.$toasted, this.page.name, this.record)) {
              this.$emit("refresh");
              this.closeModal(this.modal_name);
            }
            this.loading = false;
          }
        }
      }
    },
    linkPunchlistItem() {
      this.$refs.link_asset.tags = this.record.tags;
      if(this.platform_id) {
        this.$refs.link_asset.selected_platforms = [this.platform_id];
      }
      else {
        this.$refs.link_asset.load();
      }
    },
    unlinkAsset(asset_id) {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then((res) => {
        if (res) {
          this.loading = true;
          this.$http
              .post(this.$store.getters.appUrl + "v2/punchlistitems/unlinkasset", { punchlistitem_id: this.record.id, asset_id: asset_id })
              .then(() => {
                this.loading = false;
                this.$toasted.success(this.$store.getters.translate("success"));
                this.load(this.record.id, this.punchlist_owner_id);
              })
              .catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toasted.error(error);
                }
                this.loading = false;
              });
        }
      });
    },
    sendReviewRequest() {
      this.$refs.send_review_request.openModal('send_review_request');
    },
    logsAdded() {
      let sender_id = this.$store.state.user.id;
      let receiver_id = null;
      if(this.employee_id === this.punchlist_owner_id) {
        if(this.record.active) {
          receiver_id = this.record.follow_up_by; //employee ID
        }
        else {
          receiver_id = this.record.punched_by; //employee ID
        }
      }
      else {
        receiver_id = this.punchlist_owner_id; //employee ID
      }
      this.$http
          .post(this.$store.getters.appUrl + "v2/punchlistitems/logs-added", {
            punchlistitem_internal_id: this.record.internal_id,
            punchlist_id: this.record.punchlist_id,
            sender_id: sender_id,
            receiver_id: receiver_id,
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
          });
    },
    async archive() {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then(async (res) => {
        if (res) {
          this.loading = true;
          if (await helpFunctions.deleteModel(this.$toasted, this.page.name, this.record.id)) {
            this.$emit("refresh");
            this.closeModal(this.modal_name);
          }
          this.loading = false;
        }
      });
    },
    canEditCustomRules(field) {
      //ONLY TECHNICAL AUTHORITY PERSON CAN EDIT TECHNICAL AUTHORITY APPROVED
      if (field.name === 'technical_authority_approved') {
        if(this.employee_id === this.record.technical_authority_by) {
          return true;
        }
        else {
          return false;
        }
      }
      //DEFAULT FIELDS THAT NEVER CAN BE EDITED
      if (field.name === 'date_punched' || field.name === 'punched_by' || field.name === 'punchlist_id') {
        return false;
      }
      //CAN BE EDITED ONLY BY PUNCHLIST ITEM CREATOR OR PUNCHLIST OWNER
      if (this.employee_id !== this.record.punched_by && this.employee_id !== this.punchlist_owner_id) {
        return false;
      }
      //WHEN ACTIVE OR CLOSED ONLY PUNCHLIST OWNER CAN EDIT
      if ((this.record.active || this.record.status) && this.employee_id !== this.punchlist_owner_id) {
        return false;
      }
      //FIELDS THAT CAN BE EDITED ONLY BY PUNCHLIST OWNER
      if ((field.name === 'active' || field.name === 'status' || field.name === 'follow_up_by' || field.name === 'follow_up_wo_number' ||
          field.name === 'technical_authority_by' || field.name === 'technical_authority_required' || field.name === 'rejected' || field.name === 'reject_reason') &&
          this.employee_id !== this.punchlist_owner_id) {
        return false;
      }
      //FIELDS THAT CAN BE EDITED ONLY BY PUNCHLIST CREATOR
      if ((field.name === 'short_description' || field.name === 'long_description') && this.employee_id !== this.record.punched_by) {
        return false;
      }
      //CAN NOT ACTIVATE OR CLOSE PUNCHLIST ITEM IF TECHNICAL AUTHORITY REQUIRED
      if ((field.name === 'active' || field.name === 'status') && this.record.technical_authority_required && !this.record.technical_authority_approved) {
        return false;
      }
      //CAN NOT ACTIVATE OR CLOSE PUNCHLIST ITEM IF IT IS REJECTED. SHORT AND LONG DESCRIPTIONS CAN NOT BE EDITED TOO
      if ((field.name === 'active' || field.name === 'status' || field.name === 'short_description' || field.name === 'long_description') && this.record.rejected) {
        return false;
      }
      //DEFAULT
      return true;
    },
    usualFieldDataChanged(data) {
      this.record[data[0]] = data[1];
    },
    async getFields() {
      this.loading = true;
      const response = await helpFunctions.getFields(this.page.model, "show_on_update");
      this.tags_required = response.tags_required;
      this.usual_fields = response.usual_fields;
      this.loading = false;
    },
    saveFiles() {
      if (!this.record.new_files) {
        return false;
      }
      this.loading = true;
      const formData = new FormData();
      this.record.new_files.forEach((file) => {
        formData.append("attachment[]", file);
      });
      formData.append("punchlistitem_id", this.record.id);
      this.$http
          .post(this.$store.getters.appUrl + "v2/punchlistitems/uploadfiles", formData, { headers: { "Content-Type": "multipart/form-data" } })
          .then(() => {
            this.loading = false;
            this.$toasted.success(this.$store.getters.translate("successfully_saved"));
            this.load(this.record.id, this.punchlist_owner_id);
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    addDropFile(e) {
      this.loading = true;
      const formData = new FormData();
      for (var i = 0; i < e.dataTransfer.files.length; i++) {
        formData.append("attachment[]", e.dataTransfer.files[i]);
      }
      formData.append("punchlistitem_id", this.record.id);
      this.$http
          .post(this.$store.getters.appUrl + "v2/punchlistitems/uploadfiles", formData, { headers: { "Content-Type": "multipart/form-data" } })
          .then(() => {
            this.loading = false;
            this.$toasted.success(this.$store.getters.translate("successfully_saved"));
            this.load(this.record.id, this.punchlist_owner_id);
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    download(file) {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/files/" + file.id, {
            responseType: "blob",
          })
          .then((response) => {
            this.loading = false;
            const blob = new Blob([response.data], { type: file.content_type });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = file.name;
            link.click();
            URL.revokeObjectURL(link.href);
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    deleteFile(file) {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then((res) => {
        if (res) {
          this.loading = true;
          this.$http
              .delete(this.$store.getters.appUrl + "v2/files/" + file.id)
              .then(() => {
                this.$http
                    .post(this.$store.getters.appUrl + "v2/punchlistitems/file-deleted", {
                      punchlistitem_id: this.record.id,
                      punchlistitem_internal_id: this.record.internal_id,
                      punchlist_id: this.record.punchlist_id,
                      file_name: file.name,
                    }).then(() => {
                      this.loading = false;
                      this.$toasted.success(this.$store.getters.translate("successfully_deleted"));
                      this.load(this.record.id);
                    }).catch((error) => {
                      if (this.$store.getters.isLoggedIn) {
                        this.$toasted.error(error);
                      }
                    });
              }).catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toasted.error(error);
                }
                this.loading = false;
              });
        }
      });
    },
    canEdit(id = 0) {
      return this.$can("edit", this.$createEntity(this.page.model, {id}));
    },
    canDelete(id = 0) {
      return this.$can("delete", this.$createEntity(this.page.model, {id}));
    },
  },
};
</script>