<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name" :current="record.name"/>
    <v-row>
      <v-col>
        <v-expansion-panels v-model="panel" multiple>
          <base-table-includable ref="punchlist_items_table" :page="page" :parent_module_id="record.id" relation_name="punchlistitems" @create="createPunchlistItem" @edit="editPunchlistItem" @change="load"/>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <base-tab-panel
          :module_name="page.name"
          :record="record"
          @change="save()"
          @archive="archive()"
          @exportPdf="exportPunchlistToPdf"
          @exportExcel="exportPunchlistToExcel"
          :loading="loading"
          :model="page.model">
          <template v-slot:main_fields>
            <v-row>
              <v-col>
                <template v-for="field in usual_fields">
                  <base-field
                      :isDisabled="!canEdit(record.id || 0) || field.name === 'date'"
                      :key="field.name"
                      :value="record[field.name]"
                      :field="field"
                      @change="usualFieldDataChanged"/>
                </template>
                <base-log-field class="mt-5" :model_type="page.model" :model_id="record.id"/>
                <base-tags v-if="tags_required" :isDisabled="!canEdit(record.id || 0)" v-model="record.tags" :modal_name="page.name"/>
              </v-col>
            </v-row>
          </template>
        </base-tab-panel>
      </v-col>
    </v-row>
    <create-punchlist-item modal_name="create_punchlist_item" ref="create_punchlist_item" :platform_id="platform_id" @edit="goToAsset" @refresh="load()"/>
    <edit-punchlist-item modal_name="edit_punchlist_item" ref="edit_punchlist_item" :platform_id="platform_id" @edit="goToAsset" @refresh="load()"/>
  </v-container>
</template>

<script>
import BaseTableIncludable from "../../components/commonComponents/BaseTableIncludableData";
import BaseTabPanel from "../../components/commonComponents/BaseTabPanel";
import CreatePunchlistItem from "../punchlistitems/Create";
import EditPunchlistItem from "../punchlistitems/Edit";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import BaseLogField from "../../components/commonComponents/BaseLogField";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: {
    BaseTableIncludable,
    BaseBreadcrumb,
    BaseTabPanel,
    BaseLogField,
    CreatePunchlistItem,
    EditPunchlistItem
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("punchlists"),
        name: "punchlists",
        model: "punchlist"
      },
      record: {},
      usual_fields: [],
      projects: [],
      tags_required: false,
      loading: false,
      platform_id: null,
      project_name: null
    };
  },
  created() {
    this.load();
  },
  methods: {
    async load() {
      this.loading = true;
      this.record = await helpFunctions.loadModel(this.$toasted, this.page.name, this.$route.params.id);
      this.updateIncludableTables();
      this.getProjectInfo();
      this.getFields();
    },
    updateIncludableTables() {
      this.$refs.punchlist_items_table.records = this.record.punchlistitems;
      this.$refs.punchlist_items_table.selected = [];
    },
    exportPunchlistToPdf() {
      this.loading = true;
      let total_items = 0;
      let hidden_items = 0;
      this.record.punchlistitems.forEach((punchlistitem) => {
        if (punchlistitem.active) {
          total_items++;
        }
        else if (!punchlistitem.rejected) {
          hidden_items++;
        }
      });
      let active_punchlistitems = [];
      let inactive_punchlistitems = [];
      let itemsPerPage = this.$refs.punchlist_items_table.options.itemsPerPage;
      this.$refs.punchlist_items_table.options.itemsPerPage = 300;
      setTimeout(() => {
        this.$refs.punchlist_items_table.current.forEach((punchlistitem) => {
          if (punchlistitem.active) {
            active_punchlistitems.push(punchlistitem);
          }
          else if (!punchlistitem.rejected) {
            inactive_punchlistitems.push(punchlistitem);
          }
        });
        this.$refs.punchlist_items_table.options.itemsPerPage = itemsPerPage;
        this.$http
            .post(this.$store.getters.appUrl + "v2/pdf/export-punchlist-to-pdf", {
              punchlist: this.record,
              active_punchlistitems: active_punchlistitems,
              inactive_punchlistitems: inactive_punchlistitems,
              total_items: total_items,
              hidden_items: hidden_items,
              tags: this.record.tags.join(", "),
              project_name: this.project_name,
              search: this.$refs.punchlist_items_table.search,
              sort_by: this.$refs.punchlist_items_table.options.sortBy[0],
              sort_desc: this.$refs.punchlist_items_table.options.sortDesc[0],
            }, {
              responseType: "blob",
            })
            .then((response) => {
              this.loading = false;
              const blob = new Blob([response.data], { type: "application/pdf" });
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = "Punchlist " + this.record.name + " " + this.record.tags.join(", ") + " " + this.record.revision + ".pdf";
              link.click();
              URL.revokeObjectURL(link.href);
            })
            .catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toasted.error(error);
              }
              this.loading = false;
            });
      }, 300);
    },
    exportPunchlistToExcel() {
      this.loading = true;
      let total_items = 0;
      this.record.punchlistitems.forEach((punchlistitem) => {
        if (punchlistitem.active) {
          total_items++;
        }
      });
      let punchlistitems = [];
      this.$refs.punchlist_items_table.current.forEach((punchlistitem) => {
        if (punchlistitem.active) {
          punchlistitems.push(punchlistitem);
        }
      });
      this.$http
          .post(this.$store.getters.appUrl + "v2/pdf/export-punchlist-to-excel", {
            punchlist: this.record,
            punchlistitems: punchlistitems,
            total_items: total_items,
            tags: this.record.tags.join(", "),
            project_name: this.project_name,
            search: this.$refs.punchlist_items_table.search,
            sort_by: this.$refs.punchlist_items_table.options.sortBy[0],
            sort_desc: this.$refs.punchlist_items_table.options.sortDesc[0],
          })
          .then((response) => {
            this.loading = false;
            const link = document.createElement("a");
            link.href = 'data: application/vnd.ms-excel, ' + response.data.replace(/ /g, '%20');
            link.download = "Punchlist " + this.record.name + " " + this.record.tags.join(", ") + " " + this.record.revision + ".xls";
            link.click();
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    async save() {
      if(!this.loading) {
        if (helpFunctions.modelFieldsFilled(this.$toasted, this.record, this.usual_fields)) {
          this.loading = true;
          if(await helpFunctions.saveModel(this.$toasted, this.page.name, this.record)) {
            await this.load();
          }
          this.loading = false;
        }
      }
    },
    async archive() {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then(async (res) => {
        if (res) {
          this.loading = true;
          if (await helpFunctions.deleteModel(this.$toasted, this.page.name, this.record.id)) {
            await this.$router.push("/" + this.page.name);
          }
          this.loading = false;
        }
      });
    },
    goToAsset(asset_id) {
      this.$router.push({name: "Platforms", params: { asset_id: asset_id }});
    },
    createPunchlistItem() {
      this.$refs.create_punchlist_item.load(this.record.id, this.record.tags, this.record.owner_id);
    },
    editPunchlistItem(item) {
      this.$refs.edit_punchlist_item.load(item.id, this.record.owner_id);
    },
    async getProjectInfo() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/projects/short-info/" + this.record.project_id)
          .then((response) => {
            this.loading = false;
            this.platform_id = response.data.platform_id;
            this.project_name = response.data.name;
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    usualFieldDataChanged(data) {
      this.record[data[0]] = data[1];
    },
    async getFields() {
      this.loading = true;
      const response = await helpFunctions.getFields(this.page.model, "show_on_update");
      this.tags_required = response.tags_required;
      this.usual_fields = response.usual_fields;
      this.loading = false;
    },
    canEdit(id = 0) {
      return this.$can("edit", this.$createEntity(this.page.model, {id}));
    },
  },
  computed: {
    panel: {
      set(value) {
        let payload = {
          module_name: this.page.name,
          value: value,
        };
        this.$store.dispatch("setPanel", payload);
      },
      get() {
        return this.$store.getters.getPanel(this.page.name);
      },
    },
  },
};
</script>

<style>
.v-label {
  font-size: 14px
}
</style>